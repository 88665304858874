.user_mode {
   footer.snFooterContainer {
      background: none;

      #siteFooter {
         color: #bbb;

         a {
            color: #bbb;
         }

         li#poweredByNGIN a {
            width: 100%;

            &:after {
               content: url("https://app-assets1.sportngin.com/logo_images/logo.svg");
               left: calc(50% - 60px);
               width: 120px;
            }
         }

         & > ul {
            display: flex;
            flex-flow: column wrap;
            border-top: 1px solid #3a4450;
            padding: 10px;

            li {
               text-align: center;
            }
         }
      }
   }
}
@media only screen and (min-width: 48em) {
   .user_mode {
      footer.snFooterContainer {
         #siteFooter {
            & > ul {
               flex-flow: row wrap;
               justify-content: space-between;

               li {
                  text-align: left;
               }
            }
         }

         li#poweredByNGIN {
            width: 120px;

            a {
               width: 120px;

               &:after {
                  left: 0;
                  width: 100%;
               }
            }
         }
      }
   }
}
@media only screen and (min-width: 64em) {
   // Desktop (1024px)
}
