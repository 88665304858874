$primaryColor: #05152b;
$backgroundColor: #061221;
$siteNum: '12967';
$assetPath: 'https://assets.ngin.com/site_files/'+$siteNum+'/_site';
html body{
  padding-top: 0px;
}
body.edit_mode.home {
  #mobileView {
    display: none;
  }
  .svgMap {
    float: none;
  }
}
body.home{
  #displayBodyHeader{
    display: none;
  }
}
html.has-mobile-nav{
  body.home{
    &:before{
      content: '';
      height: auto;
    }
  }
}
body.user_mode.home {
  background-color: $backgroundColor;
  #topNav, #mobileView, #topNavPlaceholder, #siteHeader{
    display: none;
  }
  .layoutContainer{
    background: transparent;
    max-width: 768px;
    margin: auto;
    width: 100%;
    &.mapOverlay{
      background: rgba(#05152b,0.6);
      padding: 30px 30px 0px 30px;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  .site-background {
    background-image: url($assetPath+'/images/map/background3.jpg');
    background-color: $backgroundColor;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-position: center top;
    background-attachment: scroll;
    background-repeat: no-repeat;
  }

  #siteContainer {
    background-color: transparent;
  }
  .pageEl{
    &.bannerLogo{
      .heroPhotoElement{
        img{
          max-width: 400px;
        }
      }
    }
  }
  .textBlockElement {
    //width: 300px;
    max-height: 300px;
    overflow: auto;
    >h3, * {
      color: white;
    }
    a{
      color: #BD731A;
    }
  }
  .heroPhotoElement{
    img{
      border: 0px;
      width: auto;
      margin: auto;
    }
  }
  @media (max-width: 768px) {

    .site-background {
      background-image: url($assetPath+'/images/map/background-mobile.jpg');
    }
    #siteContainer{
      margin-top: 150px;
    }
    #panelTwo {
      //display: none;
      .layoutContainer{
        display: none;
        &.showMobile{
          display: block;;
        }
      }
    }

    #mobileView {
      display: block;
      margin-top: 30px;

      * {
        vertical-align: middle;
      }

      >a{
        display: block;
        padding-bottom: 30px;
        background: rgba(255,255,255,.2);
        padding: 10px;
        border-bottom: #1C2027 solid 2px;

        &:hover {
            text-decoration: none;
          }
        .maplist-logo{
          width: 35%;
          display: inline-block;
          img {
            width: 75%;
            margin: auto;
            display: block;
          }
        }
        >span {
          display: inline-block;
          width: 57%;
          color: white;
          font-size: 5vw;
          padding-left: 30px;
          text-transform: uppercase;
        }

        >i {
          color: white;
          font-size: 7vw;
        }
      }
    }
  }
}

.svgMap{
  position: relative;
  float: right;
  width: 100%;
}
#previewState{
  position: fixed;
  top:0;
  left:0;
  padding: 20px;
  padding-bottom: 5px;
  background: rgba(5, 21, 43, .9);
  z-index: 9999;
  color:#fff;
  display:none;
  a{
    display:block;
    text-decoration:none;
    img{
      max-width:200px;
      display:block;
      margin:auto;
      position:relative;
      text-align:center;
      width: 100%;
      margin-top: -30px
    }
    h2{
      text-decoration:none;
      color:#fff;
      font:100 1.4em/1.6em 'Helvetica Neue', Helvetica, Arial, sans-serif;
      letter-spacing: 1px;
      text-align:center;
      max-width:350px;
    }
  }
}
//

html.has-mobile-nav body:before {
  display: none;
}
html:not(.collapsed-mobile-nav):not(.has-sub-nav).nav-fixed .site-background{
  margin-top: -40px;
}
body.user_mode.home .heroPhotoElement img{
  max-width:100%;
  padding-top:40px;
}
